import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { collection, query, where, getDocs, addDoc } from "firebase/firestore";
import { db } from "./firebase";

function RedirectPage() {
  const { id } = useParams();

  async function getIpAddress() {
    const response = await fetch('https://api.ipify.org/?format=json');
    const data = await response.json();
    return data.ip;
  }

  async function traceUserData(shortUrl, originalUrl) {
    const userAgent = navigator.userAgent;
    let referrer = document.referrer;
    const timestamp = new Date().toISOString();
    let ip = await getIpAddress();

    if(referrer === "") {
      referrer = "Direct";
    }
    await addDoc(collection(db, "redirects"), {
      shortUrl,
      originalUrl,
      userAgent,
      referrer,
      timestamp,
      ip: ip, // Note: Client-side can't get real IP
    });
  }

  async function searchByShortUrl(shortUrl) {
    const q = query(collection(db, "urls"), where("shortUrl", "==", shortUrl));
    const querySnapshot = await getDocs(q);
  
    if (querySnapshot.empty) {
      console.log("No matching documents.");
      return null;
    }
  
    let originalUrl = null;
    querySnapshot.forEach((doc) => {
      originalUrl = doc.data().originalUrl;
    });
  
    return originalUrl;
  }
  const hostUrl = `${window.location.protocol}//${window.location.hostname}${![80,443].includes(window.location.port) ? `:${window.location.port}` : ""}`;
  
  const shortUrl = `${id}`;
  useEffect(() => {
    const fetchUrl = async () => {
    //   const docRef = doc(db, "urls", id); 
      // find document by short url
    const originalUrl = await searchByShortUrl(shortUrl);
    console.log(originalUrl);
    await traceUserData(shortUrl, originalUrl);

    // setTimeout(() => {
        window.location.href = originalUrl;
    //   }, 1000);
    };
    fetchUrl();
  }, [id]);

  return <div>Redirecting...</div>;
}

export default RedirectPage;
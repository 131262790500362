import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyCZKA15cyOuFTqcVk5HfpNXTNqkV02GIfQ",
    authDomain: "xlocal-me.firebaseapp.com",
    projectId: "xlocal-me",
    storageBucket: "xlocal-me.appspot.com",
    messagingSenderId: "779260304735",
    appId: "1:779260304735:web:18d43d5b8cf24f0b58eaea",
    measurementId: "G-LLEK7QM2RY"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db };
import { useEffect, useState } from "react";
import { collection, addDoc, query, where, getDocs, doc, deleteDoc } from "firebase/firestore";
import { nanoid } from "nanoid";
import { db } from "./firebase";
import { getAuth } from "firebase/auth";

function UrlShortener() {
  const [originalUrl, setOriginalUrl] = useState("");
  const [shortUrl, setShortUrl] = useState("");
  const [urlList, setUrlList] = useState([]);
  const auth = getAuth();
  const user = auth.currentUser;
  const hostUrl = `${window.location.protocol}//${window.location.hostname}`;

  const createShortUrl = async (originalUrl) => {
    const urlsRef = collection(db, "urls");
    const q = query(urlsRef, where("originalUrl", "==", originalUrl));
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      // If the URL exists, return the existing shortUrl
      return querySnapshot.docs[0].data().shortUrl;
    }

    // If the URL doesn't exist, create a new short URL
    const shortUrl = nanoid(5);
    await addDoc(urlsRef, {
      originalUrl,
      shortUrl,
      createdAt: new Date().toISOString(),
      userId: user.uid
    });
    console.log(user);
    return shortUrl;
  };

  const handleDelete = async (id) => {
    await deleteDoc(doc(db, "urls", id));
    fetchUrls();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const result = await createShortUrl(originalUrl);
    setShortUrl(result);
    fetchUrls();
  };

  useEffect(() => {
    if (user) {
      fetchUrls();
    }
  }, [user]);

  const fetchUrls = async () => {
    const urlsRef = collection(db, "urls");
    const q = query(urlsRef, where("userId", "==", user.uid));
    const querySnapshot = await getDocs(q);
    const urls = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setUrlList(urls);
  };

  return (
    <div className="d-flex flex-column justify-content-center align-items-center vh-100">
      <div>
      <form onSubmit={handleSubmit}>
        <div className="input-group mb-3">
        <input
          type="text"
          className="form-control"
          value={originalUrl}
          onChange={(e) => setOriginalUrl(e.target.value)}
          placeholder="Enter original URL"
        />
        
        <button className="btn btn-primary" type="submit">Shorten URL</button>
        </div>
        
     {shortUrl && <div className="alert alert-info pb-0" role="alert"><p>
      Shortened URL: <a target="_blank" rel="noreferrer nooepener" href={`${hostUrl}/${shortUrl}`}>{hostUrl + '/' + shortUrl}</a></p></div>}
      </form>
      
      </div>
      {Array.isArray(urlList) && urlList.length > 0 && <div className="mt-4 w-100 p-3">
        <h3>Your Shortened URLs</h3>
        <ul className="list-group">
          {urlList.map((url) => (
            <li key={url.id} className="list-group-item d-flex justify-content-between align-items-center">
              <div>
              <a href={`${hostUrl}/${url.shortUrl}`} target="_blank" rel="noreferrer noopener">
                {hostUrl}/{url.shortUrl}
                
              </a><br />
              <small className="text-muted" style={{textDecoration:"none"}}>{url.originalUrl}</small>
              </div>
              <button className="btn btn-danger btn-sm" onClick={() => handleDelete(url.id)}>Delete</button>
            </li>
          ))}
        </ul>
      </div>}
    </div>
  );
}

export default UrlShortener;